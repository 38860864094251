@font-face {
  font-family: "Barlow";
  src: url("../webfonts/Barlow-Bold.woff2") format("woff2"),
    url("../webfonts/Barlow-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../webfonts/Barlow-Medium.woff2") format("woff2"),
    url("../webfonts/Barlow-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../webfonts/Barlow-Regular.woff2") format("woff2"),
    url("../webfonts/Barlow-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../webfonts/Barlow-SemiBold.woff2") format("woff2"),
    url("../webfonts/Barlow-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("../webfonts/Kanit-Bold.woff2") format("woff2"),
    url("../webfonts/Kanit-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*font-family: 'Barlow Condensed', sans-serif;*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Barlow";
  font-weight: normal;
  color: #222222 !important;
  overflow-x: hidden;
}

a {
  color: #106eea;
  text-decoration: none;
}

a:hover {
  color: #9d9d9d !important;
  text-decoration: none !important;
}
p {
  color: #555555;
}
h1,
h2,
h3,
h4,
h5,
h6 {
}

*:focus {
  outline: none !important;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #106eea;
  border-top-color: #e2eefd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #106eea;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #3284f1;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 86px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header.fixed-top {
  height: 70px;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #222222;
}

#header .logo a span {
  color: #106eea;
}

#header .logo img {
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
#header .navbar-brand {
  font-size: 44px;
  font-family: "Kanit" !important;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 20px;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  font-size: 28px;
  font-family: "Barlow Condensed", sans-serif !important;
  /*font-family: "Barlow";*/
  font-weight: 300;
  color: #222222;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #106eea;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #9d9d9d;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  right: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 5px 20px;
  font-weight: 400;
  text-decoration: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #106eea;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.sign-links {
  display: flex;
  align-items: center;
  /*  font-family: "Barlow";*/
  font-family: "Barlow Condensed", sans-serif;
  font-size: 24px;
}
.sign-links span {
  padding: 0 15px;
}
.sign-links li,
.sign-links a {
  padding: 0;
  text-decoration: none;
  cursor: pointer;
}
.sign-links li:hover,
.sign-links a:hover {
  text-decoration: none;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 85vh;
  background: url("../images/banner-bg.png") top left;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.47);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
}

/*#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #222222;
  font-family: "Poppins", sans-serif;
}

#hero h1 span {
  color: #106eea;
}*/

#hero h2 {
  color: #fff;
  margin: 0 0 30px 0;
  font-size: 64px;
  font-weight: 400;
  font-family: "Barlow Condensed", sans-serif !important;

  text-align: center;
  padding: 0 25px;
  line-height: 1.2;
}

.banner-search form .inner-form {
  background: #fff;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgb(0 0 0 / 15%);
  border-radius: 30px;
}
.banner-search form .inner-form .input-field {
  height: 66px;
}
.banner-search form .inner-form .input-field input {
  height: 100%;
  background: transparent;
  border: 0;
  display: block;
  width: 100%;
  padding: 10px 30px;
  font-size: 26px;
  color: #000;
  font-weight: 400;
}
.banner-search form .inner-form .input-field.second-wrap {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.banner-search form .inner-form .input-field.third-wrap {
  width: 74px;
}
.banner-search form .inner-form .input-field.third-wrap .btn-search {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  border: 0;
  cursor: pointer;
  background: #fff;
  transition: all 0.2s ease-out, color 0.2s ease-out;
  border-left: 1px solid #cbcbcb;
  border-radius: 0 30px 30px 0;
}
.banner-search form .inner-form .input-field.third-wrap .btn-search img {
  width: 36px;
}
.hiw-link {
  font-size: 40px;
  text-decoration: underline;
  font-family: "Barlow Condensed", sans-serif;
  color: #fff;
  font-weight: 500;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .btn-get-started,
  #hero .btn-watch-video {
    font-size: 13px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #e8ecec;
}
.section-bg-gray {
  background-color: #f4f4f4;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #106eea;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 10px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: #106eea;
}

.section-title p {
  margin: 0 auto;
  font-weight: 600;
}

.section-title.logo-heading h3 {
  font-size: 44px;
  text-transform: uppercase;
  font-family: "Kanit";
  color: #231f20;
}
.section-titleL {
  text-align: left;
  padding-bottom: 30px;
}

.section-titleL h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #106eea;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-titleL h3 {
  margin: 10px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #222222;
}

.section-titleL h3 span {
  color: #106eea;
}

.section-titleL p {
  margin: 0 auto;
  font-weight: 600;
}

.section-titleL.logo-heading h3 {
  font-size: 44px;
  text-transform: uppercase;
  font-family: "Kanit";
  color: #231f20;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #f1f6fe;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.featured-services .icon-box::before {
  content: "";
  position: absolute;
  background: #cbe0fb;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.featured-services .icon-box:hover::before {
  background: #106eea;
  top: 0;
  border-radius: 0px;
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  font-size: 48px;
  line-height: 1;
  color: #106eea;
  transition: all 0.3s ease-in-out;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #111;
}

.featured-services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.featured-services .icon-box:hover .title a,
.featured-services .icon-box:hover .description {
  color: #fff;
}

.featured-services .icon-box:hover .icon i {
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.about .content ul li:first-child {
  margin-top: 35px;
}

.about .content ul i {
  background: #fff;
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
  font-size: 24px;
  padding: 20px;
  margin-right: 15px;
  color: #106eea;
  border-radius: 50px;
}

.about .content ul h5 {
  font-size: 18px;
  color: #555555;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}
.img-overlap {
  position: relative;
  height: 576px;
}
.pos-rel {
  position: relative;
}
.img2pos {
  position: absolute;
  top: 158px;
  left: 135px;
}
.black-btn {
  background: #141414;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
}
.black-btn:hover {
  color: #fff !important;
  background: #000;
}
.black-trans-btn {
  background: transparent;
  border: 1px solid #dddddd;
  font-size: 18px;
  color: #222222;
  padding: 15px 35px;
  text-transform: uppercase;
  font-weight: bold;
}
.black-trans-btn:hover {
  border-color: #000;
  color: #000;
}
.black-btn img,
.black-trans-btn img {
  width: 27px;
}
.black-btn span,
.black-trans-btn span {
  margin-right: 15px;
}

/*--------------------------------------------------------------
# How-it-works
--------------------------------------------------------------*/
.how-it-works .row {
  position: relative;
}
.how-it-works .row:before {
  content: "";
  background: url("../images/dashed-line.png") repeat-x left center;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(50%);
}
.hiwb-icon {
  position: relative;
  height: 150px;
}
.hiwb-icon:after {
  content: "";
  background: #000;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -8px;
  left: 46%;
  transform: translateX(46%);
}
.hiw-box h2 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  z-index: 9;
}
.hiwb-icon {
  text-align: center;
}
.hiwb-text {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  min-height: 150px;
  position: relative;
}
.hiwb-number {
  color: #f6f6f6;
  font-size: 130px;
  font-weight: 700;
  line-height: 1.2;
  position: absolute;
  left: 0;
  top: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #f1f6fe;
}

.counts .count-box i {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #106eea;
  color: #fff;
  width: 56px;
  height: 56px;
  line-height: 0;
  border-radius: 50px;
  border: 5px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #062b5b;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 1px solid #e2eefd;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f1f6fe;
  border-radius: 4px;
  border: 1px solid #deebfd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.services .icon-box .icon i {
  color: #3b8af2;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i {
  color: #106eea;
}

.services .icon-box:hover .icon {
  border-color: #106eea;
}

/*--------------------------------------------------------------
# Testimonial
--------------------------------------------------------------*/
.gr-bg {
  background: url(../images/testimonials-bg.jpg) no-repeat bottom center;
  background-size: cover;
}
.testimonial .section-title {
  padding-bottom: 0;
}
.testimonial .item {
  padding: 0 15px;
}
.testimonial .item {
  background-color: #fff;
  padding: 50px;
  border-radius: 0;
  margin: 30px 10px;
  border: 1px solid #dddddd;
  position: relative;
}
.testimonial .item .text-coma {
  position: absolute;
  top: -15px;
  left: 50px;
  background: #fff;
  width: 38px;
  height: 30px;
  padding: 2px;
}
.testimonial .owl-carousel.owl-drag .owl-item {
}

.testimonial .content p {
  font-size: 18px;
  margin-bottom: 35px;
  line-height: 30px;
}
.testimonial h5 {
  font-size: 20px;
  color: #222222;
}
.testimonial .rating-icon i,
.testimonial .rating-icon svg {
  color: #f8b81f;
  font-size: 12px;
}
.testimonial .author-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}
.testimonial .author-info .thumb {
  margin-right: 20px;
  position: relative;
  height: auto;
  width: auto;
}
.testimonial .author-info .thumb img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}
.testimonial .author-info .info {
  margin-right: 15px;
  /*-webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;*/
}
.testimonial .author-info .info .title {
  margin-bottom: 0;
  font-size: 18px;
  color: #222222;
}
.testimonial .author-info .info .subtitle {
  font-size: 14px;
  color: #888888;
}

.testimonial .owl-theme .owl-dots .owl-dot.active span,
.testimonial .owl-theme .owl-dots .owl-dot:hover span {
  background: #222222;
  width: 12px;
  height: 12px;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #106eea;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #222222;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #555555;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #3c3c3c;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #106eea;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: #6ba7f5;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #106eea;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #106eea;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 0;
  background: #f6f6f6;
  text-align: center;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .member-info {
  padding: 30px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 22px;
  color: #222222;
  text-transform: uppercase;
}

.bx-shadow {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 9%);
}
.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.news {
  padding: 90px 0;
}
.news .box {
  background: #fff;
  text-align: left;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.09);
  border-radius: 0;
  position: relative;
  overflow: hidden;
  padding: 0;
}
.lnb-pic {
  position: relative;
}
.lnb-pic img {
  width: 100%;
}
.tag-line {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #222;
  background: #fff;
  padding: 5px 15px;
  font-weight: bold;
}
.news-content {
  padding: 30px;
}
.posted-by {
  display: flex;
  color: #222;
  font-size: 16px;
  margin-bottom: 30px;
}
.posted-by strong {
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
}
.posted-by span {
  margin-left: 10px;
}
.news-content h3 {
  font-size: 22px;
  color: #222;
  font-weight: bold;
}
.news-content p {
  font-size: 18px;
  color: #555555;
  line-height: 1.4;
}
.news-read-more {
  border: 1px solid #eeeeee;
  padding: 30px 30px;
}
.news-read-more a {
  font-size: 18px;
  color: #222;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Optimizing
--------------------------------------------------------------*/
.optimizing h2 {
  font-size: 40px;
  font-weight: bold;
}
.opt-heading {
  border-right: 1px solid #000;
}
.opt-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.opt-text {
  display: flex;
  flex-direction: column;
}
.opt-text span {
  font-weight: 500;
  font-size: 24px;
}
.opt-text strong {
  font-weight: 600;
  font-size: 36px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #106eea;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #b3d1fa;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #106eea;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #106eea;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #3b8af2;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #444444;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 80px 0 50px 0;
  text-align: center;
  font-size: 15px;
}

#footer .footer-top {
  padding: 0 0 30px 0;
}
#footer .footer-top .container {
  border-top: 1px solid #000;
  padding-top: 60px;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 400;
}

#footer .footer-top .footer-contact h3 span {
  color: #106eea;
}

#footer .footer-top .footer-contact p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  color: #888888;
  padding-right: 15px;
}

#footer .footer-top h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 400;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0 0 0 15px;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #106eea;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #888888;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 18px;
}
#footer .footer-top .footer-links.gbtext ul li a {
  color: #7fa35c;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #106eea;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #106eea;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #3b8af2;
  color: #fff;
  text-decoration: none;
}

.footer-logo {
  margin-right: 30px;
}
.footer-logo h2 {
  font-size: 32px;
  text-transform: uppercase;
  color: #000;
  font-family: "Kanit";
}
.footer-social {
  margin-top: 40px;
}
.footer-social a {
  color: #aaaaaa;
  margin: 10px;
}
.footer-social a:hover {
  color: #000;
}
.newsletter-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nws-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.nwsb-text {
  text-align: left;
}
.nwsb-text h2 {
  font-size: 36px;
  color: #000000;
  font-weight: bold;
}
.nwsb-text p {
  font-style: italic;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.git-sc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #000;
  color: #000000;
  font-size: 18px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.git-icon {
  margin-right: 20px;
}
.git-icon img {
  width: 40px;
  height: 40px;
}
.git-text {
  display: flex;
  flex-direction: column;
}
.git-text span {
  font-weight: 300;
}
.git-text strong {
  font-weight: 500;
}

.copyright {
  border-top: 1px solid #000;
  padding: 20px 0;
}
.copyright p {
  font-weight: 300;
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}
.cp-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cp-links {
  color: #000;
  font-weight: 300;
  margin-left: 25px;
}
.cp-links a {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  text-decoration: underline;
  margin: 0 10px;
}
.cp-links a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
}

@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}

.underline-none {
  text-decoration: none !important;
}

.in-up-form .modal-header .btn.btn-primary {
  padding: 0;
  background: transparent;
  color: #000;
  border: none;
}

.in-up-form .modal-title {
  display: flex;
  font-size: 18px;
  align-items: center;
}
.in-up-form .modal-title h3 {
  font-size: 20px;
  padding-left: 12px;
  line-height: 1.2;
  font-weight: 500;
}
.in-up-form .input-group {
  display: block;
}
.PasswordeyeIcon {
  position: absolute;
  top: 30px;
  right: 10px;
}
.rem-forgot {
  display: flex;
  justify-content: space-between;
}
.btn-small {
  padding: 10px 20px !important;
  font-size: 13px !important;
}

.in-up-form .form-control {
  font-size: 13px;
}
.forgot_password {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}
.forgot_password:hover {
  text-decoration: none;
  color: #9d9d9d;
}
.in-up-form .rs-picker-toggle-wrapper {
  width: 100%;
}
.in-up-form .ssBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.in-up-form .ssBox input {
  margin-left: 10px;
}
.rs-picker-menu {
  z-index: 9999 !important;
}
.testimonial .info-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.UserEditForm {
  position: absolute;
  right: 25px;
  top: 37px;
}
.form-control.rs-picker {
  padding: 0;
  border: none;
}
.add-form label {
  margin-bottom: 0.5rem !important;
}
#hero.prop-search-banner {
  height: 220px;
  padding: 15px 0;
}
#hero.prop-search-banner h2 {
  font-size: 34px;
  margin-bottom: 15px;
}
#hero.prop-search-banner .banner-search form .inner-form .input-field {
  height: 56px;
}
#hero.prop-search-banner .banner-search form .inner-form .input-field input {
  padding: 8px 30px;
  font-size: 22px;
}
.prp-search .box {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.prp-search .news-content {
  padding: 20px;
  width: 100%;
}
.prp-search .posted-by {
  margin-bottom: 15px;
}
.prp-search .news-read-more {
  padding: 20px;
  width: 100%;
}
.post-item {
  display: flex;
  padding: 20px;
}
.post-item img {
  width: 80px;
  margin-right: 15px;
}
.post-item h4 {
}
.post-item h4 a {
  color: var(--color-secondary);
  transition: 0.3s;
}
.post-item time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: rgba(var(--color-default-rgb), 0.4);
}

#header {
  transition: all 0.3s ease;
/*  position: absolute;*/
}
#header.is-sticky {
  position:fixed;
  width: 100%;
  top:0;
  left:0;
  transition: all 0.3s ease;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
}
.feature-list>div {
  background: rgba(0, 0, 0, .1);
  color:#000;
  padding:10px 15px;
  border-radius: 10px;
  margin-right:5px;
  margin-bottom: 5px;
}
.feature-list>div:hover {
  background: rgba(0, 0, 0, 1);
  color:#fff;
}
.feature-list>div input {
  margin-right: 10px;
}

.post-author {
    padding: 40px 20px 25px 20px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    position: relative;
    margin-top: 45px;
    margin-left: 15px;
}
.post-author .pa-title {
    position: absolute;
    top: -15px;
    left: -15px;
}
.post-author .pa-title h2 {
    background: #141414;
    border-radius: 10px 0;
    color: #fff;
    padding: 8px 15px;
    font-size: 16px;
    display: flex;
    line-height: inherit;
    letter-spacing: 0.5px;
}
.post-author .pa-content {
    display: flex;
    align-items: flex-start;
}
.post-author img {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    margin-bottom: 20px;
}
.post-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #000;
}
.post-author p {
    font-size: 15px;
    line-height: 1.4;
    color: rgba(24, 24, 24, 0.8);
}
ul.fc-list {
  list-style: none;
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
}
ul.fc-list li {
  margin-bottom: 5px;
}
ul.fc-list li svg {
  margin-right:10px;
}

ul.fc-list li strong {
  margin-left:5px;
}


ul.fc-list-2 {
  list-style: none;
  margin:0;
  padding:0;
  display: flex;
  justify-content: space-between;
}
ul.fc-list-2 li a {
  margin-bottom: 5px;
  background: rgba(0, 0, 0, .1);
  padding:10px;
  display: flex;
  color:#000;
  align-items: center;
}
ul.fc-list-2 li a:hover {
  background: #000;
  color:#fff !important;
}
ul.fc-list-2 li svg {
  margin-right:10px;
}

ul.fc-list-2 li strong {
  margin-left:5px;
}
p.b-date {
  background: rgba(0, 0, 0, .2);
  color:#000;
  padding:5px 15px;
  font-size: 16px;
  margin-bottom:8px;
  border-radius: 10px;
}

/*----------------------------------------------*/
/*Responsive CSS*/
/*----------------------------------------------*/

@media (max-width: 1440px) {
  #header .navbar-brand {
    font-size: 38px;
  }
  .navbar a,
  .navbar a:focus {
    font-size: 26px;
  }
  .sign-links,
  .sign-links a,
  .sign-links li {
    font-size: 22px;
  }
  #hero h2 {
    font-size: 56px;
  }
  .hiw-link {
    font-size: 36px;
  }
  #hero {
    height: 80vh;
  }
  .section-title h3,
  .section-titleL h3 {
    font-size: 30px;
  }
  .propertyDedu .member .member-info h4 {
    font-size: 20px;
  }
  .section-title.logo-heading h3 {
    font-size: 40px;
  }
  .about .content h3 {
    font-size: 28px;
  }
  .hiw-box h2 {
    font-size: 20px;
  }
  .optimizing h2 {
    font-size: 34px;
  }
  .opt-text strong {
    font-size: 32px;
  }
  .opt-text span {
    font-size: 20px;
  }
  .black-btn,
  .black-trans-btn {
    font-size: 16px;
    padding: 13px 26px;
  }
  .news-content h3 {
    font-size: 20px;
  }
  .nwsb-text h2 {
    font-size: 32px;
  }
  #footer .footer-top .footer-contact h3,
  #footer .footer-top h4 {
    font-size: 20px;
    margin: 0 0 25px 0;
  }
  #footer .footer-top .footer-links ul li {
    padding: 8px 0;
  }
  .git-sc {
    font-size: 18px;
  }
  #footer .footer-newsletter {
    padding: 70px 0 40px 0;
  }
  .copyright p,
  .cp-links a {
    font-size: 15px;
  }
  #footer .footer-top .container {
    padding-top: 50px;
  }
}

@media (max-width: 1280px) {
  .propertyDedu,
  section {
    padding: 50px 0;
  }
  .overlap {
    height: 540px;
  }
  .news {
    padding: 65px 0;
  }
  #footer .footer-newsletter {
    padding: 60px 0 35px 0;
  }
  #hero h2 {
    font-size: 52px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 16px;
  }
}
@media (max-width: 1190px) {
  .propertyDedu,
  section {
    padding: 40px 0;
  }
  .section-title {
    padding-bottom: 20px;
  }
  #header .navbar-brand {
    font-size: 32px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 15px;
  }
  .sign-links,
  .sign-links a,
  .sign-links li {
    font-size: 20px;
  }
  #hero h2 {
    font-size: 45px;
    margin: 0 0 24px 0;
  }
  .hiw-link {
    font-size: 32px;
  }
  .section-title h3 {
    font-size: 28px;
  }
  .propertyDedu .member .member-info h4 {
    font-size: 18px;
  }
  .propertyDedu .member .member-info {
    padding: 26px 15px;
  }
  .section-title.logo-heading h3 {
    font-size: 36px;
  }
  .img-overlap {
    position: relative;
    height: 525px;
  }
  .img2pos {
    position: absolute;
    top: 140px;
    left: 80px;
  }
  .hiwb-text {
    min-height: 130px;
  }
  .hiwb-icon img {
    height: 110px;
  }
  .hiwb-icon:after {
    bottom: 1px;
  }
  .optimizing h2 {
    font-size: 28px;
  }
  .opt-text span {
    font-size: 18px;
  }
  .black-btn,
  .black-trans-btn {
    font-size: 16px;
    padding: 10px 22px;
  }
  .testimonial .item {
    padding: 36px;
  }
  .testimonial .content p {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 26px;
  }
  .opt-text strong {
    font-size: 28px;
  }
  .section-title h3,
  .section-titleL h3 {
    font-size: 28px;
  }
  .news-content h3 {
    font-size: 18px;
    font-weight: 600;
  }
  .news-content {
    padding: 22px;
  }

  .news-content p {
    font-size: 16px;
  }
  .news-read-more {
    padding: 16px 22px;
  }
  .nwsb-text h2 {
    font-size: 28px;
  }
  .nwsb-text p {
    font-size: 16px;
  }
  .news-read-more a {
    font-size: 16px;
  }
  .news-content {
    padding: 20px;
  }
  .posted-by {
    margin-bottom: 20px;
  }
  .footer-logo h2 {
    font-size: 28px;
  }
  #footer .footer-newsletter {
    padding: 45px 0 30px 0;
  }
  #footer .footer-top .container {
    padding-top: 40px;
  }
  #footer .footer-top .footer-contact h3,
  #footer .footer-top h4 {
    font-size: 18px;
    margin: 0 0 20px 0;
  }
  .footer-social {
    margin-top: 28px;
  }
  #footer .footer-top .footer-links ul {
    padding: 0 0 0 10px;
  }
  #footer .footer-top .footer-links ul a {
    font-size: 16px;
  }
  #footer .footer-top .footer-links ul li {
    padding: 6px 0;
  }
  .git-icon img {
    width: 30px;
    height: 30px;
  }
  .git-icon {
    margin-right: 10px;
  }
  .git-sc {
    font-size: 16px;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  #footer .footer-top {
    padding: 0 0 20px 0;
  }
  .copyright {
    border-top: 1px solid #303030;
    padding: 16px 0;
  }
  #footer .footer-top .footer-contact p {
    font-size: 15px;
  }
  .banner-search form .inner-form .input-field {
    height: 58px;
  }
  .news {
    padding: 50px 0 65px 0;
  }
  .inner-page-h {
    min-height: calc(100vh - 142px);
  }
}

@media (max-width: 991px) {
  #header .navbar-collapse {
    padding: 0 0 15px 0;
    position: absolute;
    width: 100%;
    background: #fff;
    top: 100%;
    left: 0;
    z-index: 9;
    box-shadow: 0px 10px 15px rgb(0 0 0 / 9%);
  }
  #hero h2 {
    font-size: 36px;
    margin: 0 0 15px 0;
  }
  .hiw-link {
    font-size: 26px;
  }
  .img2pos {
    position: absolute;
    top: 154px;
    left: 210px;
  }
  .img-overlap {
    position: relative;
    height: 532px;
  }
  .opt-heading {
    border: none;
  }
  .optimizing h2 {
    text-align: center;
    margin-bottom: 25px;
  }
  .testimonial .item {
    padding: 28px;
  }
  .nwsb-text h2 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .nwsb-text p {
    margin: 0;
  }
  .navbar li {
    padding: 5px 0;
  }

  .how-it-works .row:before {
    /*    background: url(../img/dashed-line-H.png) repeat-y top center;*/
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    transform: translate(50%, 0);
  }
  .hiwb-icon:after {
    bottom: -8px;
    left: 48.2%;
    transform: translateX(50%);
  }
  .hiwb-icon img {
    height: 110px;
    background: #fff;
  }
  .hiwb-text {
    min-height: auto;
    margin-bottom: 25px;
    padding-top: 15px;
  }
  .hiwb-number {
    font-size: 110px;
    left: 30%;
    top: -50px;
  }
  .navbar a,
  .navbar a:focus {
    font-size: 24px;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-toggler:hover {
    background: #000;
  }
  .navbar-toggler:hover span {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(223deg)
      brightness(229%) contrast(310%);
  }
}

@media (max-width: 767px) {
  section,
  .news {
    padding: 40px 0;
  }
  .navbar a,
  .navbar a:focus {
    font-size: 20px;
  }
  #header {
    height: 68px;
  }
  .sign-links span {
    padding: 0 10px;
  }
  .navbar-toggler {
    padding: 5px 10px;
  }
  .sign-links,
  .sign-links a,
  .sign-links li {
    font-size: 18px;
  }
  #hero h2 {
    font-size: 24px;
    margin: 0 0 12px 0;
  }
  #header .navbar-brand {
    font-size: 24px;
  }
  .banner-search form .inner-form .input-field input {
    padding: 6px 20px;
    font-size: 20px;
  }
  .banner-search form .inner-form .input-field {
    height: 54px;
  }
  .hiw-link {
    font-size: 22px;
  }
  .section-title h3,
  .section-titleL h3 {
    font-size: 22px;
  }
  .section-title.logo-heading h3 {
    font-size: 32px;
  }
  .about .content ul h5 {
    font-size: 16px;
  }
  .about .content ul p {
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 0;
    flex-basis: 48%;
  }
  .hiw-box h2 {
    font-size: 18px;
  }
  .hiwb-number {
    left: 20%;
  }
  .hiwb-icon {
    height: auto;
  }
  .hiwb-icon img {
    height: 85px;
  }
  .optimizing h2 {
    font-size: 24px;
  }
  #footer .footer-top .footer-contact h3,
  #footer .footer-top h4 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
  .cp-content {
    flex-direction: column;
    text-align: center;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 20px;
  }
  .footer-logo h2 {
    font-size: 26px;
  }
  .cp-links {
    margin-left: 0;
  }
  #footer .footer-newsletter {
    padding: 35px 0 25px 0;
  }
  #footer .footer-top .container {
    padding-top: 25px;
  }
  .footer-social {
    margin-top: 20px;
  }

  .img2pos {
    display: none;
  }
  .newsletter-content {
    flex-direction: column;
  }
  .nws-box {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .nwsb-text {
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-logo {
    margin-right: 0;
  }
  .section-titleL {
    text-align: center;
    padding-bottom: 15px;
  }
  .btns-align {
    text-align: center;
    margin-bottom: 20px;
  }
  .opt-content {
    flex-direction: column;
    justify-content: center;
  }
  .opt-text {
    text-align: center;
    margin-bottom: 15px;
  }
  .optimizing h2 {
    margin-bottom: 15px;
  }
  .img-overlap {
    height: auto;
  }
  #header .navbar .container-fluid {
    padding: 0;
  }
  .breadcrumbs h2 {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  #header .navbar-brand {
    font-size: 20px;
  }
  .sign-links,
  .sign-links a,
  .sign-links li {
    font-size: 16px;
  }
  .navbar-toggler {
    padding: 3px 7px;
  }
  .sign-links span {
    padding: 0 7px;
  }
  #hero h2 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
  .hiw-link {
    font-size: 20px;
  }
  .banner-search form .inner-form .input-field {
    height: 50px;
  }
  .section-title h3,
  .section-titleL h3 {
    font-size: 20px;
  }
  .propertyDedu .member .member-info h4 {
    font-size: 16px;
  }
  .section-title.logo-heading h3 {
    font-size: 28px;
  }
  .about .content h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .about .content ul li:first-child {
    margin-top: 20px;
  }

  .about .content ul li {
    margin-bottom: 10px;
    flex-direction: column;
  }
  .about .content ul h5 {
    flex-basis: 100%;
    border: none;
  }
  .about .content ul p {
    flex-basis: 100%;
    padding-right: 10px;
  }
  .hiwb-icon img {
    height: 70px;
  }
  .hiwb-icon:after {
    bottom: -15px;
    left: 46.5%;
    transform: translateX(50%);
    width: 12px;
    height: 12px;
  }
  .optimizing h2 {
    font-size: 20px;
  }
  .opt-text strong {
    font-size: 24px;
  }
  .testimonial .item {
    padding: 25px 15px;
  }
  .testimonial .author-info .thumb {
    margin-right: 15px;
  }
  .testimonial .author-info .thumb img {
    border-radius: 50%;
    width: 42px;
    height: 42px;
  }
  .testimonial .content p {
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 22px;
  }
  .posted-by {
    margin-bottom: 15px;
  }
  .nwsb-text h2 {
    font-size: 22px;
    margin-bottom: 0;
  }
  .copyright p,
  .cp-links a {
    font-size: 13px;
  }
}

.btn-right {
  float: right;
}

.resetPassword {
  width: 65vh;
  border: 2px solid grey;
  border-radius: 8px;
  padding: 20px !important;
}

.reset-password-instruction {
  margin-top: 20px;
  margin-bottom: 20px;
}

.resetPassword .changepasword .input-group {
  display: block;
}

.resetPassword .changepasword .input-group .btn {
  font-size: 13px;
}

.resetPassword .changepasword .input-group .btn svg {
  margin: 0 6px;
}

.resetPassword .changepasword .input-group .changepasswordbtn {
  float: right;
}

@media (max-width: 920px) {
  .resetPassword {
    width: 100%;
  }

  .resetPassword .changepasword .input-group .btn {
    float: none;
    width: 100%;
  }
}